var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"centered":"","title":'Agregar Usuario - ' + _vm.subtitle,"hide-footer":"","no-close-on-backdrop":"","size":"lg"},model:{value:(_vm.localShowModal),callback:function ($$v) {_vm.localShowModal=$$v},expression:"localShowModal"}},[_c('b-overlay',{attrs:{"rounded":"","responsive":"","variant":"transparent","opacity":"0.33","blur":"2px"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('validation-observer',{ref:"createForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.createUser.apply(null, arguments)}}},[_c('b-form-group',{attrs:{"label":""}},[_c('b-row',[_c('b-col',{staticClass:"mb-1",attrs:{"xl":"12","md":"12","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Colegio","label-for":"colegio"}},[_c('validation-provider',{attrs:{"name":"colegio","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"mx-select",attrs:{"disabled":_vm.fromParent,"state":errors.length > 0 ? false : null,"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"rules":"required","options":_vm.schoolsOptions,"reduce":function (item) { return item.id; },"placeholder":"Seleccione un colegio","label":"name"},model:{value:(_vm.formData.school_id),callback:function ($$v) {_vm.$set(_vm.formData, "school_id", $$v)},expression:"formData.school_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"mb-2 mt-2",attrs:{"cols":"12"}},[_c('h5',{staticClass:"mb-0"},[_vm._v("Informacion de Usuario")])]),_c('b-col',{attrs:{"md":"6","lg":"4","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Nombres","label-for":"name"}},[_c('validation-provider',{attrs:{"name":"nombre","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"maxlength":"20"},on:{"keypress":_vm.filterString},model:{value:(_vm.formData.name),callback:function ($$v) {_vm.$set(_vm.formData, "name", $$v)},expression:"formData.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"6","lg":"4","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Apellidos","label-for":"last_name"}},[_c('validation-provider',{attrs:{"name":"apellido","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"maxlength":"20"},on:{"keypress":_vm.filterString},model:{value:(_vm.formData.last_name),callback:function ($$v) {_vm.$set(_vm.formData, "last_name", $$v)},expression:"formData.last_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"6","lg":"4","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Fecha de nacimiento","label-for":"birthday"}},[_c('validation-provider',{attrs:{"name":"fecha de nacimiento","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('date-picker',{staticClass:"w-100",attrs:{"type":"date","default-value":new Date(),"disabled-date":_vm.disabledBeforeToday},model:{value:(_vm.formData.birthday),callback:function ($$v) {_vm.$set(_vm.formData, "birthday", $$v)},expression:"formData.birthday"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"6","lg":"4","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Cedula","label-for":"ci"}},[_c('validation-provider',{attrs:{"name":"ci","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"maxlength":"10"},on:{"keypress":_vm.filterNumber},model:{value:(_vm.formData.id_card),callback:function ($$v) {_vm.$set(_vm.formData, "id_card", $$v)},expression:"formData.id_card"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),(_vm.typeUser == 'student')?_c('b-col',{attrs:{"md":"6","lg":"4","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Grado","label-for":"grado"}},[_c('validation-provider',{attrs:{"name":"grado","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"grado","state":errors.length > 0 ? false : null},model:{value:(_vm.formData.grado),callback:function ($$v) {_vm.$set(_vm.formData, "grado", $$v)},expression:"formData.grado"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1):_vm._e(),(_vm.typeUser == 'student')?_c('b-col',{attrs:{"md":"6","lg":"4","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Sección","label-for":"section"}},[_c('validation-provider',{attrs:{"name":"section","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"section","state":errors.length > 0 ? false : null},model:{value:(_vm.formData.section),callback:function ($$v) {_vm.$set(_vm.formData, "section", $$v)},expression:"formData.section"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1):_vm._e(),(_vm.typeUser == 'student')?_c('b-col',{attrs:{"md":"6","lg":"4","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Aula","label-for":"aula"}},[_c('validation-provider',{attrs:{"name":"aula","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"aula","state":errors.length > 0 ? false : null},model:{value:(_vm.formData.aula),callback:function ($$v) {_vm.$set(_vm.formData, "aula", $$v)},expression:"formData.aula"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1):_vm._e(),_c('b-col',{attrs:{"md":"6","lg":"4","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Telefono"}},[_c('vue-tel-input',{attrs:{"defaultCountry":'VE',"maxlength":"15"},on:{"validate":_vm.validateNumber},model:{value:(_vm.formData.phone),callback:function ($$v) {_vm.$set(_vm.formData, "phone", $$v)},expression:"formData.phone"}})],1)],1),_c('b-col',{attrs:{"md":"6","lg":"4","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Correo electronico","label-for":"email"}},[_c('validation-provider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email","state":errors.length > 0 ? false : null,"maxlength":"40"},model:{value:(_vm.formData.email),callback:function ($$v) {_vm.$set(_vm.formData, "email", $$v)},expression:"formData.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),(!_vm.fromParent)?_c('b-col',{attrs:{"md":"6","lg":"4","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Billetera"}},[_c('validation-provider',{attrs:{"name":"billetera","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":[
                                                    { id: 1, name: 'Prepago' },
                                                    { id: 2, name: 'Pospago' } ],"label":"name","reduce":function (item) { return item.name; }},model:{value:(_vm.formData.wallet.type),callback:function ($$v) {_vm.$set(_vm.formData.wallet, "type", $$v)},expression:"formData.wallet.type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1):_vm._e(),_c('b-col',{attrs:{"md":"6","lg":"4","sm":"12"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"contraseña","vid":"password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                                var errors = ref.errors;
return [_c('label',[_vm._v("Contraseña")]),_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"register-password","type":_vm.passwordFieldType,"state":errors.length > 0 ? false : null,"name":"register-password"},model:{value:(_vm.formData.password),callback:function ($$v) {_vm.$set(_vm.formData, "password", $$v)},expression:"formData.password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"6","lg":"4","sm":"12"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"contraseña","vid":"c_password","rules":"required|confirmed:password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                                var errors = ref.errors;
return [_c('label',[_vm._v("Confirmar contraseña")]),_c('b-input-group',{class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"register-c_password","type":_vm.passwordFieldType,"state":errors.length > 0 ? false : null,"name":"register-c_password"},model:{value:(_vm.formData.c_password),callback:function ($$v) {_vm.$set(_vm.formData, "c_password", $$v)},expression:"formData.c_password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"12","lg":"12","sm":"12"}},[(_vm.formData.wallet.type == 'Pospago')?_c('b-form-group',{attrs:{"label":"Opciones"}},[_c('b-button',{staticClass:"mb-1 mb-sm-0 mr-0 mr-sm-1",attrs:{"variant":_vm.selectedOption === 'days' ? 'success' : 'primary',"block":_vm.$store.getters['app/currentBreakPoint'] === 'xs'},on:{"click":function($event){return _vm.selectOption('days')}}},[_vm._v(" Agregar días de préstamo ")]),_c('b-button',{staticClass:"mb-1 mb-sm-0 mr-0 mr-sm-1",attrs:{"variant":_vm.selectedOption === 'limit' ? 'success' : 'primary',"block":_vm.$store.getters['app/currentBreakPoint'] === 'xs'},on:{"click":function($event){return _vm.selectOption('limit')}}},[_vm._v(" Agregar límite de crédito ")]),(_vm.selectedOption === 'days')?_c('div',[_c('validation-provider',{attrs:{"name":"billetera","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                                var errors = ref.errors;
return [_c('br'),_c('v-select',{attrs:{"placeholder":"Días de préstamo","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.optionsDays,"label":"name","reduce":function (item) { return item.id; }},model:{value:(_vm.formDataV1.days),callback:function ($$v) {_vm.$set(_vm.formDataV1, "days", $$v)},expression:"formDataV1.days"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e(),(_vm.selectedOption === 'limit')?_c('b-form-group',[_c('br'),_c('b-form-input',{attrs:{"placeholder":"Límite de crédito","type":"number"},model:{value:(_vm.formDataV1.max_limit),callback:function ($$v) {_vm.$set(_vm.formDataV1, "max_limit", $$v)},expression:"formDataV1.max_limit"}})],1):_vm._e()],1):_vm._e()],1)],1),_c('b-row',{staticClass:"justify-content-evenly mt-3"},[_c('b-button',{attrs:{"type":"submit","variant":"primary"}},[_vm._v(" Agregar ")]),_c('b-button',{attrs:{"variant":"secondary"},on:{"click":_vm.closeModal}},[_vm._v(" Cancelar ")])],1)],1)],1)]}}])})],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }